import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, vShow as _vShow, withDirectives as _withDirectives, normalizeClass as _normalizeClass, withCtx as _withCtx } from "vue";
const _hoisted_1 = {
  key: 0
};
const _hoisted_2 = {
  key: 1
};
const _hoisted_3 = {
  style: {
    "position": "absolute",
    "top": "0",
    "bottom": "68px"
  }
};
const _hoisted_4 = {
  class: "topbagd systemfont"
};
const _hoisted_5 = ["src"];
const _hoisted_6 = {
  class: "btms"
};
const _hoisted_7 = {
  style: {
    "padding": "8px 12px"
  }
};
const _hoisted_8 = {
  class: "errmsg systemfont"
};
const _hoisted_9 = {
  class: "errtitle systemfont"
};
const _hoisted_10 = {
  class: "errcont systemfont"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_Loading = _resolveComponent("Loading");
  const _component_van_popup = _resolveComponent("van-popup");
  return _openBlock(), _createElementBlock("div", null, [$data.showLoading ? (_openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_Loading)])) : _createCommentVNode("", true), $data.showMain ? (_openBlock(), _createElementBlock("div", _hoisted_2, [_createElementVNode("div", _hoisted_3, [_createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.$t('ViewPage.remarks')), 1), _withDirectives(_createElementVNode("div", {
    style: _normalizeStyle({
      width: $data.tempwidth,
      margin: '0 14px'
    })
  }, [_createElementVNode("img", {
    src: $data.pdfsrc,
    style: _normalizeStyle({
      width: '100%',
      'max-height': $data.tempheight
    })
  }, null, 12, _hoisted_5)], 4), [[_vShow, $data.showPdf]])]), _withDirectives(_createElementVNode("div", _hoisted_6, [_createElementVNode("div", {
    style: _normalizeStyle({
      width: $data.tempwidth
    }),
    class: "download systemfont",
    onClick: _cache[0] || (_cache[0] = (...args) => $options.onDownload && $options.onDownload(...args))
  }, _toDisplayString(_ctx.$t('ViewPage.button')), 5), _withDirectives(_createElementVNode("div", _hoisted_7, [_createElementVNode("span", _hoisted_8, _toDisplayString($data.downerrmsg), 1)], 512), [[_vShow, $data.downerr]])], 512), [[_vShow, $data.showDownload]]), _createVNode(_component_van_popup, {
    show: $data.showFail,
    "onUpdate:show": _cache[3] || (_cache[3] = $event => $data.showFail = $event),
    round: "",
    style: {
      margin: '28px',
      padding: '20px',
      width: 'auto'
    }
  }, {
    default: _withCtx(() => [_createElementVNode("div", _hoisted_9, _toDisplayString(_ctx.$t('PopMsgViewPage.success')), 1), _createElementVNode("div", _hoisted_10, _toDisplayString($data.FailErrMsg), 1), _createElementVNode("button", {
      class: _normalizeClass($data.closeyes),
      onClick: _cache[1] || (_cache[1] = $event => this.showFail = false)
    }, _toDisplayString(_ctx.$t('ManPage.closeyes')), 3), _createElementVNode("button", {
      class: _normalizeClass($data.closecancel),
      onClick: _cache[2] || (_cache[2] = $event => this.showFail = false)
    }, _toDisplayString(_ctx.$t('ManPage.closecancel')), 3)]),
    _: 1
  }, 8, ["show"])])) : _createCommentVNode("", true)]);
}