import InputField from './InputField.vue';
import Loading from './loading.vue';
export default {
  components: {
    InputField,
    Loading
  },
  data() {
    return {
      tempwidth: document.documentElement.clientWidth - 30 + 'px',
      tempheight: document.documentElement.clientHeight - 150 + 'px',
      downerr: false,
      downerrmsg: this.$t('PopMsgViewPage.download'),
      showMain: false,
      showLoading: true,
      pdfsrc: '',
      showFail: false,
      FailErrMsg: '',
      closeyes: 'closebtny btnyes systemfont',
      closecancel: 'closebtny btncancel systemfont',
      showDownload: true,
      showPdf: true,
      timer: null
    };
  },
  mounted() {
    this.$store.commit('setPdfhref', '');
    this.searchFapiao();
  },
  methods: {
    searchFapiao() {
      clearTimeout(this.timer);
      this.timer = setTimeout(() => {
        this.showMain = true;
        this.showLoading = false;
        this.axios.get(this.$store.state.backAddress + "/cdapply/ShowFapiao/?invoiceRequestId=" + this.$store.state.invoiceRequest.Id).then(res => {
          let src = res.data.data;
          this.pdfsrc = "data:image/jpeg;base64," + src;
        });
      }, 1000);
    },
    onDownload() {
      window.open(this.$store.state.backAddress + "/cdapply/DownloadFapiao/?invoiceRequestId=" + this.$store.state.invoiceRequest.Id);
    }
  }
};